/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { Role } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleDescriptionCollection } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (role: Role, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('createRole', 'role', role)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(role, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoleById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the roles that the current user can assign in the specified organization.
         * @summary Gets the roles that the current user can assign in the specified organization.
         * @param {string} organizationId The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableRoles: async (organizationId: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAssignableRoles', 'organizationId', organizationId)
            const localVarPath = `/roles/assignable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleById: async (id: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoleById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptionsOfRole: async (id: number, offset?: number, limit?: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoleDescriptionsOfRole', 'id', id)
            const localVarPath = `/roles/{id}/role-descriptions`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of roles.
         * @param {number} [id] The unique numeric ID for identifying the role.
         * @param {string} [name] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (id?: number, name?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleById: async (id: number, role: Role, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoleById', 'id', id)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('updateRoleById', 'role', role)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(role, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(role: Role, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the roles that the current user can assign in the specified organization.
         * @summary Gets the roles that the current user can assign in the specified organization.
         * @param {string} organizationId The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignableRoles(organizationId: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignableRoles(organizationId, offset, limit, fields, expand, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleById(id: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleById(id, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleDescriptionsOfRole(id: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDescriptionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleDescriptionsOfRole(id, offset, limit, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of roles.
         * @param {number} [id] The unique numeric ID for identifying the role.
         * @param {string} [name] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(id?: number, name?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(id, name, description, offset, limit, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleById(id: number, role: Role, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleById(id, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * Create a role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(role: Role, options?: any): AxiosPromise<void> {
            return localVarFp.createRole(role, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRoleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the roles that the current user can assign in the specified organization.
         * @summary Gets the roles that the current user can assign in the specified organization.
         * @param {string} organizationId The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableRoles(organizationId: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any): AxiosPromise<RoleCollection> {
            return localVarFp.getAssignableRoles(organizationId, offset, limit, fields, expand, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleById(id: number, fields?: string, expand?: string, options?: any): AxiosPromise<Role> {
            return localVarFp.getRoleById(id, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptionsOfRole(id: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleDescriptionCollection> {
            return localVarFp.getRoleDescriptionsOfRole(id, offset, limit, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of roles.
         * @param {number} [id] The unique numeric ID for identifying the role.
         * @param {string} [name] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(id?: number, name?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleCollection> {
            return localVarFp.getRoles(id, name, description, offset, limit, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a role.
         * @param {number} id The unique numeric ID for identifying the role.
         * @param {Role} role The role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleById(id: number, role: Role, options?: any): AxiosPromise<void> {
            return localVarFp.updateRoleById(id, role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * Create a role.
     * @param {Role} role The role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRole(role: Role, options?: any) {
        return RolesApiFp(this.configuration).createRole(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role.
     * @param {number} id The unique numeric ID for identifying the role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRoleById(id: number, options?: any) {
        return RolesApiFp(this.configuration).deleteRoleById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the roles that the current user can assign in the specified organization.
     * @summary Gets the roles that the current user can assign in the specified organization.
     * @param {string} organizationId The unique numeric ID for identifying the organization.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getAssignableRoles(organizationId: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any) {
        return RolesApiFp(this.configuration).getAssignableRoles(organizationId, offset, limit, fields, expand, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a role.
     * @param {number} id The unique numeric ID for identifying the role.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoleById(id: number, fields?: string, expand?: string, options?: any) {
        return RolesApiFp(this.configuration).getRoleById(id, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id The unique numeric ID for identifying the role.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoleDescriptionsOfRole(id: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any) {
        return RolesApiFp(this.configuration).getRoleDescriptionsOfRole(id, offset, limit, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of roles.
     * @param {number} [id] The unique numeric ID for identifying the role.
     * @param {string} [name] The name of the role.
     * @param {string} [description] The description of the role.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(id?: number, name?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, options?: any) {
        return RolesApiFp(this.configuration).getRoles(id, name, description, offset, limit, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a role.
     * @param {number} id The unique numeric ID for identifying the role.
     * @param {Role} role The role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public updateRoleById(id: number, role: Role, options?: any) {
        return RolesApiFp(this.configuration).updateRoleById(id, role, options).then((request) => request(this.axios, this.basePath));
    }
}
