/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { Organization } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { OrganizationCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SiteAttributesUpdateRequestBody } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SiteMemberAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SiteMemberAttributeCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreAttributesUpdateRequestBody } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreMemberAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreMemberAttributeCollection } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a collection of organizations the currently logged in administrator user can manage.
         * @summary Get a collection of organizations the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [organizationName] The organization name.
         * @param {string} [parentOrganizationName] The parent organization name.
         * @param {string} [taskName] Limits the results to organizations that match the specified task. The following values are accepted:  * AssignRoleToUser - Returns organizations for which the current user is allowed to assign roles to other users.  * CreateAccount - Returns organizations for which the current user is allowed to create accounts.  * AssignBuyerToMarketplaceContract - Returns organizations for which the current user is allowed to assign to marketplace contracts as buyer organizations .  * CreateRegisteredCustomer - Returns organizations for which the current user is allowed to create registered customers.  * Manage - Returns organizations that the current user is allowed to manage. The organizations may be of type \&quot;O\&quot;, \&quot;OU\&quot; or \&quot;AD\&quot;.  * ManageExcludingAD - Returns organizations that the current user is allowed to manage. The organiztaion may be of type \&quot;O\&quot; or \&quot;OU\&quot;.  * ManageO - Returns organizations of type \&quot;O\&quot; that the current user is allowed to manage.  * ManageOU - Returns organizations of type \&quot;OU\&quot; that the current user is allowed to manage. 
         * @param {number} [storeId] The unique numeric ID of the store for which the organizations are being managed. This parameter should be passed if the taskName is CreateAccount, AssignBuyerToMarketplaceContract or CreateRegisteredCustomer.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [parentOrganizationId] Limits search results to only include organizations with a parent organization ID that matches the value of this parameter.
         * @param {string} [ancestorOrganizationId] Limits search results to only include organizations with an ancestor organization ID that matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetManageableOrganizations: async (offset?: number, limit?: number, organizationName?: string, parentOrganizationName?: string, taskName?: string, storeId?: number, sort?: string, parentOrganizationId?: string, ancestorOrganizationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/manageable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (parentOrganizationName !== undefined) {
                localVarQueryParameter['parentOrganizationName'] = parentOrganizationName;
            }

            if (taskName !== undefined) {
                localVarQueryParameter['taskName'] = taskName;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (parentOrganizationId !== undefined) {
                localVarQueryParameter['parentOrganizationId'] = parentOrganizationId;
            }

            if (ancestorOrganizationId !== undefined) {
                localVarQueryParameter['ancestorOrganizationId'] = ancestorOrganizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lock an organization. When locked the organization\'s status is -1.
         * @summary Lock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationLock: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationLock', 'id', id)
            const localVarPath = `/organizations/{id}/lock`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlock an organization. When unlocked the organization\'s status is 0.
         * @summary Unlock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUnlock: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationUnlock', 'id', id)
            const localVarPath = `/organizations/{id}/unlock`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an organization.
         * @summary Create an organization.
         * @param {Organization} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganization: async (body: Organization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsCreateOrganization', 'body', body)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an organization site-level attribute.
         * @summary Create an organization site-level attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganizationSiteAttribute: async (id: string, body: SiteMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsCreateOrganizationSiteAttribute', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsCreateOrganizationSiteAttribute', 'body', body)
            const localVarPath = `/organizations/{id}/site-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a store-specific organization attribute.
         * @summary Create a store-specific organization attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganizationStoreMemberAttribute: async (id: string, body: StoreMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsCreateOrganizationStoreMemberAttribute', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsCreateOrganizationStoreMemberAttribute', 'body', body)
            const localVarPath = `/organizations/{id}/store-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a site-level attribute of an organization.
         * @summary Delete a site-level attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsDeleteOrganizationSiteAttribute: async (id: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsDeleteOrganizationSiteAttribute', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsDeleteOrganizationSiteAttribute', 'name', name)
            const localVarPath = `/organizations/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a store-specific attribute of an organization.
         * @summary Delete a store-specific attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsDeleteOrganizationStoreMemberAttribute: async (id: string, storeId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsDeleteOrganizationStoreMemberAttribute', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationsDeleteOrganizationStoreMemberAttribute', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsDeleteOrganizationStoreMemberAttribute', 'name', name)
            const localVarPath = `/organizations/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization by ID.
         * @summary Get an organization by ID.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindByOrganizationId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsFindByOrganizationId', 'id', id)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @summary Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindSiteAttributeByOrganizationId: async (id: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsFindSiteAttributeByOrganizationId', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsFindSiteAttributeByOrganizationId', 'name', name)
            const localVarPath = `/organizations/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization\'s site-level, store-independent attributes.
         * @summary Get an organization\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindSiteAttributesByOrganizationId: async (id: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsFindSiteAttributesByOrganizationId', 'id', id)
            const localVarPath = `/organizations/{id}/site-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @summary Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindStoreMemberAttributeByOrganizationId: async (id: string, storeId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsFindStoreMemberAttributeByOrganizationId', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationsFindStoreMemberAttributeByOrganizationId', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsFindStoreMemberAttributeByOrganizationId', 'name', name)
            const localVarPath = `/organizations/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an organization\'s store-specific attributes.
         * @summary Get an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindStoreMemberAttributesByOrganizationId: async (id: string, storeId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsFindStoreMemberAttributesByOrganizationId', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationsFindStoreMemberAttributesByOrganizationId', 'storeId', storeId)
            const localVarPath = `/organizations/{id}/store-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organization.
         * @summary Update an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {Organization} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganization: async (id: string, body?: Organization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsUpdateOrganization', 'id', id)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organization\'s site-level attributes. Attribute values are merged at the attribute level, rather than at the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganizationSiteAttribute: async (id: string, name: string, body: SiteAttributesUpdateRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationSiteAttribute', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationSiteAttribute', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationSiteAttribute', 'body', body)
            const localVarPath = `/organizations/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organization\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganizationStoreMemberAttribute: async (id: string, storeId: number, name: string, body: StoreAttributesUpdateRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationStoreMemberAttribute', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationStoreMemberAttribute', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationStoreMemberAttribute', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsUpdateOrganizationStoreMemberAttribute', 'body', body)
            const localVarPath = `/organizations/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a collection of organizations the currently logged in administrator user can manage.
         * @summary Get a collection of organizations the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [organizationName] The organization name.
         * @param {string} [parentOrganizationName] The parent organization name.
         * @param {string} [taskName] Limits the results to organizations that match the specified task. The following values are accepted:  * AssignRoleToUser - Returns organizations for which the current user is allowed to assign roles to other users.  * CreateAccount - Returns organizations for which the current user is allowed to create accounts.  * AssignBuyerToMarketplaceContract - Returns organizations for which the current user is allowed to assign to marketplace contracts as buyer organizations .  * CreateRegisteredCustomer - Returns organizations for which the current user is allowed to create registered customers.  * Manage - Returns organizations that the current user is allowed to manage. The organizations may be of type \&quot;O\&quot;, \&quot;OU\&quot; or \&quot;AD\&quot;.  * ManageExcludingAD - Returns organizations that the current user is allowed to manage. The organiztaion may be of type \&quot;O\&quot; or \&quot;OU\&quot;.  * ManageO - Returns organizations of type \&quot;O\&quot; that the current user is allowed to manage.  * ManageOU - Returns organizations of type \&quot;OU\&quot; that the current user is allowed to manage. 
         * @param {number} [storeId] The unique numeric ID of the store for which the organizations are being managed. This parameter should be passed if the taskName is CreateAccount, AssignBuyerToMarketplaceContract or CreateRegisteredCustomer.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [parentOrganizationId] Limits search results to only include organizations with a parent organization ID that matches the value of this parameter.
         * @param {string} [ancestorOrganizationId] Limits search results to only include organizations with an ancestor organization ID that matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationGetManageableOrganizations(offset?: number, limit?: number, organizationName?: string, parentOrganizationName?: string, taskName?: string, storeId?: number, sort?: string, parentOrganizationId?: string, ancestorOrganizationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationGetManageableOrganizations(offset, limit, organizationName, parentOrganizationName, taskName, storeId, sort, parentOrganizationId, ancestorOrganizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lock an organization. When locked the organization\'s status is -1.
         * @summary Lock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationLock(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationLock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unlock an organization. When unlocked the organization\'s status is 0.
         * @summary Unlock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationUnlock(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationUnlock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an organization.
         * @summary Create an organization.
         * @param {Organization} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsCreateOrganization(body: Organization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsCreateOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an organization site-level attribute.
         * @summary Create an organization site-level attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsCreateOrganizationSiteAttribute(id: string, body: SiteMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsCreateOrganizationSiteAttribute(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a store-specific organization attribute.
         * @summary Create a store-specific organization attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsCreateOrganizationStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsCreateOrganizationStoreMemberAttribute(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a site-level attribute of an organization.
         * @summary Delete a site-level attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsDeleteOrganizationSiteAttribute(id: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsDeleteOrganizationSiteAttribute(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a store-specific attribute of an organization.
         * @summary Delete a store-specific attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsDeleteOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsDeleteOrganizationStoreMemberAttribute(id, storeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization by ID.
         * @summary Get an organization by ID.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsFindByOrganizationId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsFindByOrganizationId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @summary Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsFindSiteAttributeByOrganizationId(id: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMemberAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsFindSiteAttributeByOrganizationId(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization\'s site-level, store-independent attributes.
         * @summary Get an organization\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsFindSiteAttributesByOrganizationId(id: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMemberAttributeCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsFindSiteAttributesByOrganizationId(id, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @summary Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsFindStoreMemberAttributeByOrganizationId(id: string, storeId: number, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreMemberAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsFindStoreMemberAttributeByOrganizationId(id, storeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an organization\'s store-specific attributes.
         * @summary Get an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsFindStoreMemberAttributesByOrganizationId(id: string, storeId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreMemberAttributeCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsFindStoreMemberAttributesByOrganizationId(id, storeId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an organization.
         * @summary Update an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {Organization} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsUpdateOrganization(id: string, body?: Organization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsUpdateOrganization(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an organization\'s site-level attributes. Attribute values are merged at the attribute level, rather than at the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsUpdateOrganizationSiteAttribute(id: string, name: string, body: SiteAttributesUpdateRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsUpdateOrganizationSiteAttribute(id, name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an organization\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsUpdateOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreAttributesUpdateRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsUpdateOrganizationStoreMemberAttribute(id, storeId, name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Get a collection of organizations the currently logged in administrator user can manage.
         * @summary Get a collection of organizations the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [organizationName] The organization name.
         * @param {string} [parentOrganizationName] The parent organization name.
         * @param {string} [taskName] Limits the results to organizations that match the specified task. The following values are accepted:  * AssignRoleToUser - Returns organizations for which the current user is allowed to assign roles to other users.  * CreateAccount - Returns organizations for which the current user is allowed to create accounts.  * AssignBuyerToMarketplaceContract - Returns organizations for which the current user is allowed to assign to marketplace contracts as buyer organizations .  * CreateRegisteredCustomer - Returns organizations for which the current user is allowed to create registered customers.  * Manage - Returns organizations that the current user is allowed to manage. The organizations may be of type \&quot;O\&quot;, \&quot;OU\&quot; or \&quot;AD\&quot;.  * ManageExcludingAD - Returns organizations that the current user is allowed to manage. The organiztaion may be of type \&quot;O\&quot; or \&quot;OU\&quot;.  * ManageO - Returns organizations of type \&quot;O\&quot; that the current user is allowed to manage.  * ManageOU - Returns organizations of type \&quot;OU\&quot; that the current user is allowed to manage. 
         * @param {number} [storeId] The unique numeric ID of the store for which the organizations are being managed. This parameter should be passed if the taskName is CreateAccount, AssignBuyerToMarketplaceContract or CreateRegisteredCustomer.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [parentOrganizationId] Limits search results to only include organizations with a parent organization ID that matches the value of this parameter.
         * @param {string} [ancestorOrganizationId] Limits search results to only include organizations with an ancestor organization ID that matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetManageableOrganizations(offset?: number, limit?: number, organizationName?: string, parentOrganizationName?: string, taskName?: string, storeId?: number, sort?: string, parentOrganizationId?: string, ancestorOrganizationId?: string, options?: any): AxiosPromise<OrganizationCollection> {
            return localVarFp.organizationGetManageableOrganizations(offset, limit, organizationName, parentOrganizationName, taskName, storeId, sort, parentOrganizationId, ancestorOrganizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lock an organization. When locked the organization\'s status is -1.
         * @summary Lock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationLock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationLock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlock an organization. When unlocked the organization\'s status is 0.
         * @summary Unlock an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUnlock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationUnlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an organization.
         * @summary Create an organization.
         * @param {Organization} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganization(body: Organization, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsCreateOrganization(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an organization site-level attribute.
         * @summary Create an organization site-level attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganizationSiteAttribute(id: string, body: SiteMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsCreateOrganizationSiteAttribute(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a store-specific organization attribute.
         * @summary Create a store-specific organization attribute.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsCreateOrganizationStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsCreateOrganizationStoreMemberAttribute(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a site-level attribute of an organization.
         * @summary Delete a site-level attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsDeleteOrganizationSiteAttribute(id: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsDeleteOrganizationSiteAttribute(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a store-specific attribute of an organization.
         * @summary Delete a store-specific attribute of an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsDeleteOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsDeleteOrganizationStoreMemberAttribute(id, storeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization by ID.
         * @summary Get an organization by ID.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindByOrganizationId(id: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationsFindByOrganizationId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @summary Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindSiteAttributeByOrganizationId(id: string, name: string, options?: any): AxiosPromise<SiteMemberAttribute> {
            return localVarFp.organizationsFindSiteAttributeByOrganizationId(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization\'s site-level, store-independent attributes.
         * @summary Get an organization\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindSiteAttributesByOrganizationId(id: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteMemberAttributeCollection> {
            return localVarFp.organizationsFindSiteAttributesByOrganizationId(id, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @summary Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindStoreMemberAttributeByOrganizationId(id: string, storeId: number, name: string, options?: any): AxiosPromise<StoreMemberAttribute> {
            return localVarFp.organizationsFindStoreMemberAttributeByOrganizationId(id, storeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an organization\'s store-specific attributes.
         * @summary Get an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsFindStoreMemberAttributesByOrganizationId(id: string, storeId: number, offset?: number, limit?: number, options?: any): AxiosPromise<StoreMemberAttributeCollection> {
            return localVarFp.organizationsFindStoreMemberAttributesByOrganizationId(id, storeId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an organization.
         * @summary Update an organization.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {Organization} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganization(id: string, body?: Organization, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsUpdateOrganization(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an organization\'s site-level attributes. Attribute values are merged at the attribute level, rather than at the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganizationSiteAttribute(id: string, name: string, body: SiteAttributesUpdateRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsUpdateOrganizationSiteAttribute(id, name, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an organization\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update an organization\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the organization.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreAttributesUpdateRequestBody} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsUpdateOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreAttributesUpdateRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsUpdateOrganizationStoreMemberAttribute(id, storeId, name, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * Get a collection of organizations the currently logged in administrator user can manage.
     * @summary Get a collection of organizations the currently logged in administrator user can manage.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [organizationName] The organization name.
     * @param {string} [parentOrganizationName] The parent organization name.
     * @param {string} [taskName] Limits the results to organizations that match the specified task. The following values are accepted:  * AssignRoleToUser - Returns organizations for which the current user is allowed to assign roles to other users.  * CreateAccount - Returns organizations for which the current user is allowed to create accounts.  * AssignBuyerToMarketplaceContract - Returns organizations for which the current user is allowed to assign to marketplace contracts as buyer organizations .  * CreateRegisteredCustomer - Returns organizations for which the current user is allowed to create registered customers.  * Manage - Returns organizations that the current user is allowed to manage. The organizations may be of type \&quot;O\&quot;, \&quot;OU\&quot; or \&quot;AD\&quot;.  * ManageExcludingAD - Returns organizations that the current user is allowed to manage. The organiztaion may be of type \&quot;O\&quot; or \&quot;OU\&quot;.  * ManageO - Returns organizations of type \&quot;O\&quot; that the current user is allowed to manage.  * ManageOU - Returns organizations of type \&quot;OU\&quot; that the current user is allowed to manage. 
     * @param {number} [storeId] The unique numeric ID of the store for which the organizations are being managed. This parameter should be passed if the taskName is CreateAccount, AssignBuyerToMarketplaceContract or CreateRegisteredCustomer.
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
     * @param {string} [parentOrganizationId] Limits search results to only include organizations with a parent organization ID that matches the value of this parameter.
     * @param {string} [ancestorOrganizationId] Limits search results to only include organizations with an ancestor organization ID that matches the value of this parameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationGetManageableOrganizations(offset?: number, limit?: number, organizationName?: string, parentOrganizationName?: string, taskName?: string, storeId?: number, sort?: string, parentOrganizationId?: string, ancestorOrganizationId?: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationGetManageableOrganizations(offset, limit, organizationName, parentOrganizationName, taskName, storeId, sort, parentOrganizationId, ancestorOrganizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lock an organization. When locked the organization\'s status is -1.
     * @summary Lock an organization.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationLock(id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationLock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlock an organization. When unlocked the organization\'s status is 0.
     * @summary Unlock an organization.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationUnlock(id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationUnlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an organization.
     * @summary Create an organization.
     * @param {Organization} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsCreateOrganization(body: Organization, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsCreateOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an organization site-level attribute.
     * @summary Create an organization site-level attribute.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {SiteMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsCreateOrganizationSiteAttribute(id: string, body: SiteMemberAttribute, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsCreateOrganizationSiteAttribute(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a store-specific organization attribute.
     * @summary Create a store-specific organization attribute.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {StoreMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsCreateOrganizationStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsCreateOrganizationStoreMemberAttribute(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a site-level attribute of an organization.
     * @summary Delete a site-level attribute of an organization.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {string} name The name of the attribute to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsDeleteOrganizationSiteAttribute(id: string, name: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsDeleteOrganizationSiteAttribute(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a store-specific attribute of an organization.
     * @summary Delete a store-specific attribute of an organization.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of the attribute to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsDeleteOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsDeleteOrganizationStoreMemberAttribute(id, storeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization by ID.
     * @summary Get an organization by ID.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsFindByOrganizationId(id: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsFindByOrganizationId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
     * @summary Get an organization\'s site-level, store-independent attribute by organization ID and attribute name.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {string} name The name of the attribute to use as the search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsFindSiteAttributeByOrganizationId(id: string, name: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsFindSiteAttributeByOrganizationId(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization\'s site-level, store-independent attributes.
     * @summary Get an organization\'s site-level, store-independent attributes.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsFindSiteAttributesByOrganizationId(id: string, offset?: number, limit?: number, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsFindSiteAttributesByOrganizationId(id, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
     * @summary Get an organization\'s store-specific attribute by organization ID, store ID and attribute name.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of the attribute to use as the search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsFindStoreMemberAttributeByOrganizationId(id: string, storeId: number, name: string, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsFindStoreMemberAttributeByOrganizationId(id, storeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an organization\'s store-specific attributes.
     * @summary Get an organization\'s store-specific attributes.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsFindStoreMemberAttributesByOrganizationId(id: string, storeId: number, offset?: number, limit?: number, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsFindStoreMemberAttributesByOrganizationId(id, storeId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an organization.
     * @summary Update an organization.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {Organization} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsUpdateOrganization(id: string, body?: Organization, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsUpdateOrganization(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an organization\'s site-level attributes. Attribute values are merged at the attribute level, rather than at the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
     * @summary Update an organization\'s site-level attributes.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {string} name The name of the attribute to be updated.
     * @param {SiteAttributesUpdateRequestBody} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsUpdateOrganizationSiteAttribute(id: string, name: string, body: SiteAttributesUpdateRequestBody, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsUpdateOrganizationSiteAttribute(id, name, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an organization\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
     * @summary Update an organization\'s store-specific attributes.
     * @param {string} id The unique numeric ID for identifying the organization.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of this attribute.
     * @param {StoreAttributesUpdateRequestBody} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsUpdateOrganizationStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreAttributesUpdateRequestBody, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsUpdateOrganizationStoreMemberAttribute(id, storeId, name, body, options).then((request) => request(this.axios, this.basePath));
    }
}
