/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import { STORELOCATORACTIONS } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { useStoreLocatorValue } from "../../_foundation/context/store-locator-context";

//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import MiniCart from "./MiniCart-chc";
import LanguageToggle from "./LanguageToggle";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu-chc";
import { SearchBar } from "../widgets/search-bar";

//Redux
import { userNameSelector, loginStatusSelector } from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { SELLERS_GET_ACTION } from "../../redux/actions/sellers";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Divider, Hidden } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  StyledContainer,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledSearchBarButton,
} from "@hcl-commerce-store-sdk/react-component";
//CUSTOM UI
import { StyledHeader } from "../../elements/header";
import { selectedSellersSelector, sellersSelector } from "../../redux/selectors/sellers";
import { SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { NotificationsButton } from "./notifications-button";
import { AccountButton } from "./AccountButton";
import { MarketplacePopper } from "./MarketplacePopper";
import { Stack } from "@mui/material";
import { removeTopCategoryLinks } from "../../utils/removeTopCategoryLinks";

interface HeaderProps {
  loggedIn: boolean;
}

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  const widgetName = getDisplayName(Header);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] = useState<boolean>(false);
  const myAccountElRef = useRef<HTMLButtonElement>(null);
  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);
  const { mySite } = useSite();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const addressDetails = useSelector(addressDetailsSelector);
  const userName = useSelector(userNameSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);
  const userPreviousLoggedIn = useRef();
  const isB2B = Boolean(mySite?.isB2B);
  const loggedIn = props.loggedIn;
  const isShoppingEnabled = !isB2B || (isB2B && loggedIn);
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  const storeLocatorDispach = useStoreLocatorValue().dispatch;

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
  };
  const handleMyAccountPopperClose = () => setMyAccountPopperOpen(false);

  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(() => setMiniCartPopperOpen(false), []);

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payload,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleContractChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payload,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
    storeLocatorDispach({ type: STORELOCATORACTIONS.RESET_STORE_SELECTOR });
  };

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      navigate(ROUTES.HOME);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          const topCategories = removeTopCategoryLinks(res.data.contents);
          setTopCategories(topCategories);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(topCategories));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, language, selectedSellers]);

  useEffect(() => {
    const payloadBase: any = {
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    dispatch(SELLERS_GET_ACTION(payloadBase));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    const currentURL = window.location.href;
    if(!(currentURL.endsWith("/cart") || (currentURL.includes("/saved-list-details")) )){
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleMiniCartClick();
      }
    }
  }
  }, [success]);

  useEffect(() => {
    //splice to empty array
    cancels.splice(0, cancels.length).forEach((cancel) => {
      cancel();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);
  const turnOffSearchBar = () => setShowSearchBar(false);
  const turnOnSearchBar = () => setShowSearchBar(true);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);

  return (
    <>
      <StyledHeader>
        <StyledContainer>
          <StyledGrid container justifyContent="space-between" alignItems="center">
            <StyledGrid item>
              <Stack direction="row" spacing={{ xs: 2, md: 4, lg: 6 }} alignItems="center">
                <Hidden xsUp>
                  <button
                    className="menu-hamburger"
                    data-testid="menu-hamburger-element"
                    onClick={toggleOpen}
                  >
                    <MenuIcon />
                  </button>
                </Hidden>
                {mySite != null && (
                  <div className="header-branding">
                    <ContentRecommendationWidget {...crwProps} />
                  </div>
                )}
                <Hidden smDown>
                  <SearchBar
                    showSearchBar={showSearchBar}
                    closeSearchBar={turnOffSearchBar}
                    openSearchBar={turnOnSearchBar}
                  />
                </Hidden>
              </Stack>
            </StyledGrid>
            <StyledGrid item>
              <Stack
                alignItems="center"
                direction="row"
                spacing={{ xs: 1, sm: 2, lg: 4 }}
                style={{ padding: "1rem 0" }}
              >
                <Hidden xsUp>
                  <StyledSearchBarButton
                    onClick={toggleSearchBar}
                    className={`header-actionsButton ${showSearchBar && "active"}`}
                    variant="text"
                    color="secondary"
                  >
                    <SearchIcon />
                  </StyledSearchBarButton>
                </Hidden>
                <LanguageToggle />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ alignSelf: "center", height: "30px" }}
                />
                {/*
                  <StoreLocatorButton />
                */}
                {loggedIn ? <NotificationsButton /> : null}
                {isShoppingEnabled && (
                  <MiniCart
                    id={miniCartPopperId}
                    open={miniCartPopperOpen}
                    handleClick={handleMiniCartClick}
                    handleClose={handleMiniCartPopperClose}
                    ref={miniCartElRef}
                  />
                )}
                {sellerConfig?.showSellerList ? <MarketplacePopper {...{ sellerConfig }} /> : null}
                <AccountButton
                  {...{
                    myAccountElRef,
                    handleMyAccountClick,
                    handleMyAccountPopperClose,
                    handleOrgChange,
                    handleContractChange,
                    handleLogout,
                    myAccountPopperOpen,
                    firstName,
                    lastName,
                    isB2B,
                    loggedIn,
                  }}
                />
              </Stack>

            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
          <Hidden mdUp>
            <StyledContainer className="bottom-padding-1">
              <SearchBar
                showSearchBar={showSearchBar}
                closeSearchBar={turnOffSearchBar}
                openSearchBar={turnOnSearchBar}
              />
            </StyledContainer>
          </Hidden>
        <Hidden smDown>
          <ExpandedMenu pages={topCategories} />
        </Hidden>

        <StyledSwipeableDrawer
          anchor={useMediaQuery(theme.breakpoints.up("sm")) ? "top" : "left"}
          open={open}
          onClose={turnOffOpen}
          onOpen={turnOnOpen}
          className="header-menu"
          data-testid="header-menu-drawer-element"
        >
          <StyledContainer>
            <StyledGrid container spacing={2} className={"menu-container " + (open ? "open" : "")}>
              <MegaMenu
                menutitle={t("MegaMenu.Title")}
                pages={topCategories}
                closeMegaMenu={turnOffOpen}
              ></MegaMenu>
            </StyledGrid>
          </StyledContainer>
        </StyledSwipeableDrawer>
      </StyledHeader>
    </>
  );
};

export { Header };
