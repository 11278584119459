/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserAddressRequest } from './user-address-request';

/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {UserAddressRequest}
     * @memberof UserRequest
     */
    address?: UserAddressRequest;
    /**
     * The registrant\'s age.
     * @type {number}
     * @memberof UserRequest
     */
    age?: number;
    /**
     * The registrant\'s date of birth.
     * @type {string}
     * @memberof UserRequest
     */
    dateOfBirth?: string;
    /**
     * The department identifier for the registrant.
     * @type {string}
     * @memberof UserRequest
     */
    departmentNumber?: string;
    /**
     * A description of the registrant.
     * @type {string}
     * @memberof UserRequest
     */
    description?: string;
    /**
     * Name that is used when the name of the registrant displays in a summary list.
     * @type {string}
     * @memberof UserRequest
     */
    displayName?: string;
    /**
     * The registrant\'s status as an employee (for example, regular, permanent, contractor, or part time).
     * @type {string}
     * @memberof UserRequest
     */
    employeeType?: string;
    /**
     * The registrant\'s gender.
     * @type {string}
     * @memberof UserRequest
     */
    gender?: string;
    /**
     * The registrant\'s logon ID. Leading and trailing spaces are trimmed. When the system is configured with LDAP, the following restrictions apply, since the logonId is part of the distinguished name: <br>* Must not begin with a \'#\'.<br>* Must not contain equals sign, comma, plus sign, quotation mark, backslash, angle brackets or semicolon.
     * @type {string}
     * @memberof UserRequest
     */
    logonId?: string;
    /**
     * The name of the registrant\'s manager.
     * @type {string}
     * @memberof UserRequest
     */
    manager?: string;
    /**
     * The identifier of the registrant\'s company. Can be set when the user is created, but is otherwise read-only.
     * @type {string}
     * @memberof UserRequest
     */
    parentOrganizationId?: string;
    /**
     * The registrant\'s password. In database mode, the password is encrypted before it is saved in the database. In LDAP mode, the password is only stored on the LDAP server. This parameter is only specified during user creation/update and will never be returned in the response.
     * @type {string}
     * @memberof UserRequest
     */
    password?: string;
    /**
     * The registrant\'s password, entered a second time. This parameter is only specified during user creation/update and will never be returned in the response.
     * @type {string}
     * @memberof UserRequest
     */
    passwordVerify?: string;
    /**
     * The flag indicating whether password and passwordVerify values are encrypted. This parameter is only specified during user creation.
     * @type {boolean}
     * @memberof UserRequest
     */
    isPasswordEncrypted?: boolean;
    /**
     * The title of the user whose address is being entered (for example, Dr., Rev., Mr., or Ms.).
     * @type {string}
     * @memberof UserRequest
     */
    personTitle?: string;
    /**
     * The registrant\'s preferred currency for transactions.
     * @type {string}
     * @memberof UserRequest
     */
    preferredCurrency?: string;
    /**
     * The unique numeric ID for identifying registrant\'s preferred language.
     * @type {number}
     * @memberof UserRequest
     */
    preferredLanguageId?: number;
    /**
     * The registrant\'s preferred method of communication.
     * @type {string}
     * @memberof UserRequest
     */
    preferredCommunication?: string;
    /**
     * The registrant\'s challenge question.
     * @type {string}
     * @memberof UserRequest
     */
    challengeQuestion?: string;
    /**
     * The registrant\'s challenge answer.
     * @type {string}
     * @memberof UserRequest
     */
    challengeAnswer?: string;
    /**
     * The registrant\'s user profile type. This parameter can only be set during user registration. Available values: <br> * B - A business user profile.<br> * C - A customer user profile.
     * @type {string}
     * @memberof UserRequest
     */
    profileType?: UserRequestProfileTypeEnum;
    /**
     * Indicates whether the user wants to receive order notification SMS text messages.
     * @type {boolean}
     * @memberof UserRequest
     */
    receiveSmsNotification?: boolean;
    /**
     * The registrant\'s registration type. Available values: <br>* A - Sell-side Administrator.<br> * G - Guest Customer.<br> * R - Registered Customer.<br> * S - Site Administrator.<br>
     * @type {string}
     * @memberof UserRequest
     */
    registrationType?: string;
    /**
     * The name of the registrant\'s secretary.
     * @type {string}
     * @memberof UserRequest
     */
    secretary?: string;
    /**
     * Whether or not the user\'s account is enabled. Available values: <br> * 0 - Disabled.<br> * 1 - Enabled.
     * @type {number}
     * @memberof UserRequest
     */
    status?: number;
    /**
     * The unique numeric ID for identifying a user account policy.
     * @type {number}
     * @memberof UserRequest
     */
    userAccountPolicyId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRequestProfileTypeEnum {
    B = 'B',
    C = 'C'
}



