import { AxiosError } from "axios";

export const isIgnorableError = (error: AxiosError) => {
  if (!error.config) return false;
  const {
    config: { url = "", method = "" },
    response,
  } = error;
  const { errorMessage, errorKey } = response?.data?.errors?.[0] ?? {};

  // Logoff error
  if (url.endsWith("loginidentity/@self") && method.toLowerCase() === "delete") {
    return true;
  }

  // Contract switch error
  if (url.includes("/switchContract/") && errorMessage.includes("contractId")) {
    return true;
  }

  // Calculate after logout error
  if (url.includes("/calculate") && errorKey === "NOT_AUTHORIZED_FOR_QUERY") {
    return true;
  }

  return false;
};
