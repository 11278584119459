/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { Address } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { AddressCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { AddressNewversionRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { AddressRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * AddressesApi - axios parameter creator
 * @export
 */
export const AddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an address.
         * @summary Create an address.
         * @param {AddressRequest} body Request body. The \&quot;id\&quot; field will be generated and it should not be part of the request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesCreateAddress: async (body: AddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addressesCreateAddress', 'body', body)
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an address  by ID.
         * @summary Delete an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesDeleteByAddressId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addressesDeleteByAddressId', 'id', id)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an address by ID.
         * @summary Get an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesFindByAddressId: async (id: string, fields?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addressesFindByAddressId', 'id', id)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of addresses.
         * @summary Get a collection of addresses.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [memberId] Limits search results to only include users whose member ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesGetAddresses: async (fields?: Array<string>, offset?: number, limit?: number, lastName?: string, firstName?: string, memberId?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an address by creating a new updated version. After the update, the new address will have status \"P\" (permanent) and the old address will have status \"T\" (historical).
         * @summary Creates a new version of an address.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {AddressNewversionRequest} body Request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesUpdateByAddressId: async (id: string, body: AddressNewversionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addressesUpdateByAddressId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addressesUpdateByAddressId', 'body', body)
            const localVarPath = `/addresses/{id}/new-version`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressesApi - functional programming interface
 * @export
 */
export const AddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an address.
         * @summary Create an address.
         * @param {AddressRequest} body Request body. The \&quot;id\&quot; field will be generated and it should not be part of the request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesCreateAddress(body: AddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesCreateAddress(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an address  by ID.
         * @summary Delete an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesDeleteByAddressId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesDeleteByAddressId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an address by ID.
         * @summary Get an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesFindByAddressId(id: string, fields?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesFindByAddressId(id, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of addresses.
         * @summary Get a collection of addresses.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [memberId] Limits search results to only include users whose member ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesGetAddresses(fields?: Array<string>, offset?: number, limit?: number, lastName?: string, firstName?: string, memberId?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesGetAddresses(fields, offset, limit, lastName, firstName, memberId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an address by creating a new updated version. After the update, the new address will have status \"P\" (permanent) and the old address will have status \"T\" (historical).
         * @summary Creates a new version of an address.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {AddressNewversionRequest} body Request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesUpdateByAddressId(id: string, body: AddressNewversionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesUpdateByAddressId(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressesApi - factory interface
 * @export
 */
export const AddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressesApiFp(configuration)
    return {
        /**
         * Create an address.
         * @summary Create an address.
         * @param {AddressRequest} body Request body. The \&quot;id\&quot; field will be generated and it should not be part of the request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesCreateAddress(body: AddressRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addressesCreateAddress(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an address  by ID.
         * @summary Delete an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesDeleteByAddressId(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.addressesDeleteByAddressId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an address by ID.
         * @summary Get an address by ID.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesFindByAddressId(id: string, fields?: Array<string>, options?: any): AxiosPromise<Address> {
            return localVarFp.addressesFindByAddressId(id, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of addresses.
         * @summary Get a collection of addresses.
         * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [memberId] Limits search results to only include users whose member ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesGetAddresses(fields?: Array<string>, offset?: number, limit?: number, lastName?: string, firstName?: string, memberId?: string, sort?: string, options?: any): AxiosPromise<AddressCollection> {
            return localVarFp.addressesGetAddresses(fields, offset, limit, lastName, firstName, memberId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an address by creating a new updated version. After the update, the new address will have status \"P\" (permanent) and the old address will have status \"T\" (historical).
         * @summary Creates a new version of an address.
         * @param {string} id The unique numeric ID for identifying the address.
         * @param {AddressNewversionRequest} body Request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesUpdateByAddressId(id: string, body: AddressNewversionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addressesUpdateByAddressId(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressesApi - object-oriented interface
 * @export
 * @class AddressesApi
 * @extends {BaseAPI}
 */
export class AddressesApi extends BaseAPI {
    /**
     * Create an address.
     * @summary Create an address.
     * @param {AddressRequest} body Request body. The \&quot;id\&quot; field will be generated and it should not be part of the request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesCreateAddress(body: AddressRequest, options?: any) {
        return AddressesApiFp(this.configuration).addressesCreateAddress(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an address  by ID.
     * @summary Delete an address by ID.
     * @param {string} id The unique numeric ID for identifying the address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesDeleteByAddressId(id: string, options?: any) {
        return AddressesApiFp(this.configuration).addressesDeleteByAddressId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an address by ID.
     * @summary Get an address by ID.
     * @param {string} id The unique numeric ID for identifying the address.
     * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesFindByAddressId(id: string, fields?: Array<string>, options?: any) {
        return AddressesApiFp(this.configuration).addressesFindByAddressId(id, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of addresses.
     * @summary Get a collection of addresses.
     * @param {Array<string>} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
     * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
     * @param {string} [memberId] Limits search results to only include users whose member ID matches the value of this parameter.
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesGetAddresses(fields?: Array<string>, offset?: number, limit?: number, lastName?: string, firstName?: string, memberId?: string, sort?: string, options?: any) {
        return AddressesApiFp(this.configuration).addressesGetAddresses(fields, offset, limit, lastName, firstName, memberId, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an address by creating a new updated version. After the update, the new address will have status \"P\" (permanent) and the old address will have status \"T\" (historical).
     * @summary Creates a new version of an address.
     * @param {string} id The unique numeric ID for identifying the address.
     * @param {AddressNewversionRequest} body Request body. The \&quot;selfAddress\&quot; and \&quot;status\&quot; fields are managed and it cannot be specified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressesApi
     */
    public addressesUpdateByAddressId(id: string, body: AddressNewversionRequest, options?: any) {
        return AddressesApiFp(this.configuration).addressesUpdateByAddressId(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}
