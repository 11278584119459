/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddressRequest } from './address-request';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * The unique numeric ID for identifying the organization.
     * @type {string}
     * @memberof Organization
     */
    id?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof Organization
     */
    address?: AddressRequest;
    /**
     * The first name of the administrator.
     * @type {string}
     * @memberof Organization
     */
    administratorFirstName?: string | null;
    /**
     * The last name of the administrator.
     * @type {string}
     * @memberof Organization
     */
    administratorLastName?: string | null;
    /**
     * The middle name of the administrator.
     * @type {string}
     * @memberof Organization
     */
    administratorMiddleName?: string | null;
    /**
     * The organizational entity\'s approval status. Available values: <br>* 0 - pending approval.<br>* 1 - approved.<br>* 2 - rejected.<br>* null - approval not applicable, so considered approved.
     * @type {number}
     * @memberof Organization
     */
    approvalState?: number;
    /**
     * Describes the kind of business performed by an organization or organizational unit.
     * @type {string}
     * @memberof Organization
     */
    businessCategory?: string | null;
    /**
     * A description of the registrant.
     * @type {string}
     * @memberof Organization
     */
    description?: string | null;
    /**
     * Distinguished Name (DN) of the organization or organizational unit, for LDAP use.
     * @type {string}
     * @memberof Organization
     */
    distinguishedName?: string;
    /**
     * Whether this organization is a business entity, that is, an organization that can have business accounts. If the organization is a business entity, the value will be true, or false otherwise.
     * @type {boolean}
     * @memberof Organization
     */
    isBusinessEntity?: boolean;
    /**
     * The organization\'s legal identifier, such as the identifier assigned by a government. The value may be null for an organization whose type is an organizational unit (OU).
     * @type {string}
     * @memberof Organization
     */
    legalId?: string | null;
    /**
     * The name of the organization that the registrant represents.
     * @type {string}
     * @memberof Organization
     */
    organizationName?: string;
    /**
     * The type of the organizational entity being added. The value can be set when creating an organization, but is otherwise read-only. Available values: <br>* O - Organization.<br>* OU - Organization Unit.
     * @type {string}
     * @memberof Organization
     */
    organizationType?: string;
    /**
     * If the organization type is an organizational unit (OU), parentOrganizationId is the unique ID of the parent organization of this organization. If the organization type is an organization (O), parentOrganizationId is not required and the default value is the root organization (-2001). The value can be set when creating an organization, but is otherwise read-only.
     * @type {string}
     * @memberof Organization
     */
    parentOrganizationId?: string;
    /**
     * The name of this organization\'s parent.
     * @type {string}
     * @memberof Organization
     */
    parentOrganizationName?: string;
    /**
     * The registrant\'s preferred mode of delivery.
     * @type {string}
     * @memberof Organization
     */
    preferredDelivery?: string | null;
    /**
     * Whether or not the organization is locked. Available values: <br> * 0 - The organization is unlocked.<br> * -1 - The organization is locked.
     * @type {number}
     * @memberof Organization
     */
    status?: OrganizationStatusEnum;
    /**
     * A string used to identify the user for taxation, especially useful with tax software.
     * @type {string}
     * @memberof Organization
     */
    taxPayerId?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum OrganizationStatusEnum {
    NUMBER_0 = 0,
    NUMBER_MINUS_1 = -1
}



