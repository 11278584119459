/* tslint:disable */
/* eslint-disable */
/**
 * role-assignment-permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleAssignment } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleAssignmentCollection } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * RoleAssignmentsApi - axios parameter creator
 * @export
 */
export const RoleAssignmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a role assignment.
         * @summary Get a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentById: async (memberId: number, roleId: number, organizationId: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getRoleAssignmentById', 'memberId', memberId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getRoleAssignmentById', 'roleId', roleId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getRoleAssignmentById', 'organizationId', organizationId)
            const localVarPath = `/role-assignments/memberId:{memberId},roleId:{roleId},organizationId:{organizationId}`
                .replace(`{${"memberId"}}`, String(memberId))
                .replace(`{${"roleId"}}`, String(roleId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of role assignments.
         * @summary Get a collection of role assignments.
         * @param {number} [memberId] The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [organizationId] The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignments: async (memberId?: number, roleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/role-assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a role assignment.
         * @summary Create a role assignment.
         * @param {RoleAssignment} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAssignmentCreateRoleAssignment: async (body: RoleAssignment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('roleAssignmentCreateRoleAssignment', 'body', body)
            const localVarPath = `/role-assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role assignment.
         * @summary Delete a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAssignmentDeleteRoleAssignment: async (memberId: number, organizationId: number, roleId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('roleAssignmentDeleteRoleAssignment', 'memberId', memberId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('roleAssignmentDeleteRoleAssignment', 'organizationId', organizationId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('roleAssignmentDeleteRoleAssignment', 'roleId', roleId)
            const localVarPath = `/role-assignments/memberId:{memberId},roleId:{roleId},organizationId:{organizationId}`
                .replace(`{${"memberId"}}`, String(memberId))
                .replace(`{${"organizationId"}}`, String(organizationId))
                .replace(`{${"roleId"}}`, String(roleId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleAssignmentsApi - functional programming interface
 * @export
 */
export const RoleAssignmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleAssignmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a role assignment.
         * @summary Get a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleAssignmentById(memberId: number, roleId: number, organizationId: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleAssignmentById(memberId, roleId, organizationId, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of role assignments.
         * @summary Get a collection of role assignments.
         * @param {number} [memberId] The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [organizationId] The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleAssignments(memberId?: number, roleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleAssignments(memberId, roleId, organizationId, offset, limit, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a role assignment.
         * @summary Create a role assignment.
         * @param {RoleAssignment} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleAssignmentCreateRoleAssignment(body: RoleAssignment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleAssignmentCreateRoleAssignment(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role assignment.
         * @summary Delete a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleAssignmentDeleteRoleAssignment(memberId: number, organizationId: number, roleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleAssignmentDeleteRoleAssignment(memberId, organizationId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleAssignmentsApi - factory interface
 * @export
 */
export const RoleAssignmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleAssignmentsApiFp(configuration)
    return {
        /**
         * Get a role assignment.
         * @summary Get a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentById(memberId: number, roleId: number, organizationId: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleAssignment> {
            return localVarFp.getRoleAssignmentById(memberId, roleId, organizationId, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of role assignments.
         * @summary Get a collection of role assignments.
         * @param {number} [memberId] The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [organizationId] The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignments(memberId?: number, roleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleAssignmentCollection> {
            return localVarFp.getRoleAssignments(memberId, roleId, organizationId, offset, limit, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a role assignment.
         * @summary Create a role assignment.
         * @param {RoleAssignment} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAssignmentCreateRoleAssignment(body: RoleAssignment, options?: any): AxiosPromise<void> {
            return localVarFp.roleAssignmentCreateRoleAssignment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role assignment.
         * @summary Delete a role assignment.
         * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
         * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAssignmentDeleteRoleAssignment(memberId: number, organizationId: number, roleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.roleAssignmentDeleteRoleAssignment(memberId, organizationId, roleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleAssignmentsApi - object-oriented interface
 * @export
 * @class RoleAssignmentsApi
 * @extends {BaseAPI}
 */
export class RoleAssignmentsApi extends BaseAPI {
    /**
     * Get a role assignment.
     * @summary Get a role assignment.
     * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
     * @param {number} roleId The unique numeric ID for identifying the role.
     * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentsApi
     */
    public getRoleAssignmentById(memberId: number, roleId: number, organizationId: number, fields?: string, expand?: string, options?: any) {
        return RoleAssignmentsApiFp(this.configuration).getRoleAssignmentById(memberId, roleId, organizationId, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of role assignments.
     * @summary Get a collection of role assignments.
     * @param {number} [memberId] The unique numeric ID for identifying the user or organization of the member that has a role.
     * @param {number} [roleId] The unique numeric ID for identifying the role.
     * @param {number} [organizationId] The unique numeric ID for identifying the organization where the role is being played.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentsApi
     */
    public getRoleAssignments(memberId?: number, roleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any) {
        return RoleAssignmentsApiFp(this.configuration).getRoleAssignments(memberId, roleId, organizationId, offset, limit, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a role assignment.
     * @summary Create a role assignment.
     * @param {RoleAssignment} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentsApi
     */
    public roleAssignmentCreateRoleAssignment(body: RoleAssignment, options?: any) {
        return RoleAssignmentsApiFp(this.configuration).roleAssignmentCreateRoleAssignment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role assignment.
     * @summary Delete a role assignment.
     * @param {number} memberId The unique numeric ID for identifying the user or organization of the member that has a role.
     * @param {number} organizationId The unique numeric ID for identifying the organization where the role is being played.
     * @param {number} roleId The unique numeric ID for identifying the role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentsApi
     */
    public roleAssignmentDeleteRoleAssignment(memberId: number, organizationId: number, roleId: number, options?: any) {
        return RoleAssignmentsApiFp(this.configuration).roleAssignmentDeleteRoleAssignment(memberId, organizationId, roleId, options).then((request) => request(this.axios, this.basePath));
    }
}
